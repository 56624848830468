<script setup>
import { onMounted, ref, computed } from "vue";
import { register } from "swiper/element/bundle";
const { isMobile } = useDevice();
register();

const props = defineProps({
  clients: Array,
});
const swiperEl = ref(null);
const isEnd = ref(false);
const isBeginning = ref(false);

const nextBtn = () => {
  swiperEl.value.swiper.slideNext();
};

const prevtBtn = () => {
  swiperEl.value.swiper.slidePrev();
};
</script>

<template>
  <swiper-container
    :slidesPerView="isMobile ? 1 : 2"
    :centeredSlides="false"
    :spaceBetween="10"
    :navigation="false"
    :loop="true"
    class="mt-10"
    ref="swiperEl"
  >
    <swiper-slide
      v-for="(client, index) in clients"
      :key="index"
      :virtualIndex="index"
      class="h-auto"
    >
      <div
        class="border border-black16 rounded-lg bg-white px-4 lg:px-8 py-8 lg:py-10 h-full"
      >
        <div class="h-10 flex items-center">
          <img
              :src="`/img/clients/${client.clientLogo}`"
              class="w-32"
          />
        </div>
        <h3 class="font-normal mt-4">{{ client.clientName }}</h3>
        <div class="description text-black60 italic mt-4">
          {{ client.description }}
        </div>
        <div class="flex justify-center mt-4">
          <svg-icon
            v-for="(star, i) in client.stars"
            :key="i"
            name="solution/star"
            class="w-4 h-4"
          />
        </div>
      </div>
    </swiper-slide>
  </swiper-container>
  <div class="flex justify-center mt-3">
    <span
      class="text-black cursor-pointer"
      :class="isBeginning ? 'text-jade' : 'text-dark'"
      @click="prevtBtn"
    >
      <svg-icon name="common/arrow" class="w-2.5 h-3 -rotate-180" />
    </span>
    <span
      class="ml-3 cursor-pointer"
      :class="isEnd ? 'text-jade' : 'text-dark'"
      @click="nextBtn"
    >
      <svg-icon name="common/arrow" class="w-2.5 h-3" />
    </span>
  </div>
</template>
